import logo from './logo.svg';
import React, { useState, useEffect } from 'react';
import './App.css';
import Axios from 'axios';
import Clock from 'react-live-clock';
import moment from 'moment';
import Marquee from "react-fast-marquee";

const appHeight = () => {
  const doc = document.documentElement
  doc.style.setProperty('--app-height', `${window.innerHeight}px`)
}
window.addEventListener('resize', appHeight)
appHeight()

const dateVariable = moment(new Date()).format("dddd, DD MMMM, yyyy");

function App() {

    useEffect(() => {
    // Redirect to Instagram on component mount
    window.location.href = "https://instagram.com/swagdoubt";
  }, []);

  let audio = new Audio("/sound.mp3")

  const start = () => {
    audio.play()
  }

  const [price, setPrice] = useState("");
  const [priceBTC, setBTC] = useState("");
  const [priceSOL, setSOL] = useState("");
  const [priceADA, setADA] = useState("");
  const [priceUSDT, setUSDT] = useState("");
  const [priceUNI, setUNI] = useState("");
  const [priceMATIC, setMATIC] = useState("");
  const [priceLINK, setLINK] = useState("");
  const [priceDOGE, setDOGE] = useState("");
  const [priceALGO, setALGO] = useState("");
  const [priceDAI, setDAI] = useState("");
  const [priceXLM, setXLM] = useState("");
  const [priceICP, setICP] = useState("");
  const [priceDOT, setDOT] = useState("");
  const [priceMANA, setMANA] = useState("");
  const [priceENS, setENS] = useState("");
  const [priceAXS, setAXS] = useState("");
  const [priceEOS, setEOS] = useState("");
  const [priceBAT, setBAT] = useState("");

  useEffect(() => {
  Axios.get("https://api.coinbase.com/v2/prices/ETH-USD/buy").then(
    (response) => {
    setPrice(response.data.data.amount);
    })
  })

  useEffect(() => {
  Axios.get("https://api.coinbase.com/v2/prices/SOL-USD/buy").then(
    (response) => {
      setSOL(response.data.data.amount);
    })
  })

  useEffect(() => {
  Axios.get("https://api.coinbase.com/v2/prices/BTC-USD/buy").then(
    (response) => {
      setBTC(response.data.data.amount);
    })
  })

  useEffect(() => {
  Axios.get("https://api.coinbase.com/v2/prices/SOL-USD/buy").then(
    (response) => {
      setSOL(response.data.data.amount);
    })
  })

  useEffect(() => {
  Axios.get("https://api.coinbase.com/v2/prices/ADA-USD/buy").then(
    (response) => {
      setADA(response.data.data.amount);
    })
  })

  useEffect(() => {
  Axios.get("https://api.coinbase.com/v2/prices/USDT-USD/buy").then(
    (response) => {
      setUSDT(response.data.data.amount);
    })
  })

  useEffect(() => {
  Axios.get("https://api.coinbase.com/v2/prices/UNI-USD/buy").then(
    (response) => {
      setUNI(response.data.data.amount);
    })
  })

  useEffect(() => {
  Axios.get("https://api.coinbase.com/v2/prices/MATIC-USD/buy").then(
    (response) => {
      setMATIC(response.data.data.amount);
    })
  })

  useEffect(() => {
  Axios.get("https://api.coinbase.com/v2/prices/LINK-USD/buy").then(
    (response) => {
      setLINK(response.data.data.amount);
    })
  })

  useEffect(() => {
  Axios.get("https://api.coinbase.com/v2/prices/DOGE-USD/buy").then(
    (response) => {
      setDOGE(response.data.data.amount);
    })
  })

  useEffect(() => {
  Axios.get("https://api.coinbase.com/v2/prices/ALGO-USD/buy").then(
    (response) => {
      setALGO(response.data.data.amount);
    })
  })
 
  useEffect(() => {
  Axios.get("https://api.coinbase.com/v2/prices/DAI-USD/buy").then(
    (response) => {
      setDAI(response.data.data.amount);
    })
  })
 
  useEffect(() => {
  Axios.get("https://api.coinbase.com/v2/prices/XLM-USD/buy").then(
    (response) => {
      setXLM(response.data.data.amount);
    })
  })
 
  useEffect(() => {
  Axios.get("https://api.coinbase.com/v2/prices/ICP-USD/buy").then(
    (response) => {
      setICP(response.data.data.amount);
    })
  })

  
  useEffect(() => {
  Axios.get("https://api.coinbase.com/v2/prices/DOT-USD/buy").then(
    (response) => {
      setDOT(response.data.data.amount);
    })
  })

  useEffect(() => {
  Axios.get("https://api.coinbase.com/v2/prices/MANA-USD/buy").then(
    (response) => {
      setMANA(response.data.data.amount);
    })
  })

  useEffect(() => {
  Axios.get("https://api.coinbase.com/v2/prices/AXS-USD/buy").then(
    (response) => {
      setAXS(response.data.data.amount);
    })
  })

  useEffect(() => {
  Axios.get("https://api.coinbase.com/v2/prices/ENS-USD/buy").then(
    (response) => {
      setENS(response.data.data.amount);
    })
  })

  useEffect(() => {
  Axios.get("https://api.coinbase.com/v2/prices/EOS-USD/buy").then(
    (response) => {
      setEOS(response.data.data.amount);
    })
  })

  useEffect(() => {
  Axios.get("https://api.coinbase.com/v2/prices/BAT-USD/buy").then(
    (response) => {
      setBAT(response.data.data.amount);
    })
  })

  return (
    <>
    <div className="MQWrap">
    <Marquee>
    <p className="Ticker">ETH: ${price}</p>
    <p className="Ticker">BTC: ${priceBTC}</p>
    <p className="Ticker">SOL: ${priceSOL}</p>
    <p className="Ticker">ADA: ${priceADA}</p>
    <p className="Ticker">USDT: ${priceUSDT}</p>
    <p className="Ticker">UNI: ${priceUNI}</p>
    <p className="Ticker">MATIC: ${priceMATIC}</p>
    <p className="Ticker">LINK: ${priceLINK}</p>
    <p className="Ticker">DOGE: ${priceDOGE}</p>
    <p className="Ticker">ALGO: ${priceALGO}</p>
    <p className="Ticker">DAI: ${priceDAI}</p>
    <p className="Ticker">XLM: ${priceXLM}</p>
    <p className="Ticker">ICP: ${priceICP}</p>
    <p className="Ticker">DOT: ${priceDOT}</p>
    <p className="Ticker">MANA: ${priceMANA}</p>
    <p className="Ticker">AXS: ${priceAXS}</p>
    <p className="Ticker">ENS: ${priceENS}</p>
    <p className="Ticker">EOS: ${priceEOS}</p>
    <p className="Ticker">BAT: ${priceBAT}</p>
    </Marquee>
    </div>
    <div className="App">
    <button className="TopBut" onClick={start}>SIMULATE WORK™ ENVIRONMENT</button>
      <img alt="my logo" src={logo} />
      <p className="TR">
      {dateVariable}
      <br></br> 
      <Clock format={'h:mm:ssa'} ticking={true}/> </p>
      <p className="BL">Enquiries<br></br>
afphicklin@gmail.com</p>
      <p className="BR">ETH Price<br></br>
      ${price}</p>
      <p className="MT">an award winning designer and developer, based in London, working across multiple fields incl. branding, interface, industrial, and type.</p>
    </div>
    <svg class="fixed inset-0 transform -translate-x-full">
        <defs>
            <filter id="n0">
                <feTurbulence type="turbulence" baseFrequency="0.1 0.1" numOctaves="20" result="NOISE"></feTurbulence>
                <feGaussianBlur in="SourceGraphic" result="BLURRED" stdDeviation="8.5"></feGaussianBlur>
                <feDisplacementMap in2="NOISE" in="BLURRED" scale="50" xChannelSelector="R" yChannelSelector="G"></feDisplacementMap>
            </filter>
            
            <filter id="n1">
                
                <feTurbulence type="fractalNoise" baseFrequency="0.001 0.001" numOctaves="5" result="NOISE" seed="133931"></feTurbulence>
                <feGaussianBlur in="SourceGraphic" result="BLURRED" stdDeviation="0"></feGaussianBlur>
                <feDisplacementMap id="displacer" in2="NOISE" in="BLURRED" scale="43" xChannelSelector="R" yChannelSelector="R" result="DISPLACED"></feDisplacementMap>
                
            </filter>
        </defs>
    </svg>
    </>
  );
}

export default App;
